<!--
 * @Author: your name
 * @Date: 2021-04-08 18:58:29
 * @LastEditTime: 2021-04-08 18:58:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \projectc\src\views\home\index.vue
-->
<template>

    <div  class="container bg_fff flex_grow_1" >
			<div class="PTB_3 relative flex_rowC fontS_16">
				<span>首页</span>
				<img @click="goMsg" class="search absolute" style="right: 5%" src="../../assets/search2.png" alt="">
			</div>	
			

			<div v-if="showLoading == false" class="PLR_3">
				<!--轮播图-->
				<div class="PTB_3">
						<van-swipe :autoplay="3000">
								<van-swipe-item class="H_40vw" v-for="(image, index) in bannerData" :key="index">
										<img v-if="image.img == '' "  src="../../assets/banner_img1.png" class="WH_100 B_radius_10 object_fit_contain"/>
										<img v-else  :src="image.img" class="WH_100 B_radius_10 object_fit_contain"/>

								</van-swipe-item>
						</van-swipe>
				</div>

				<!--合作企业-->
				<div >
					<div class="flex_row_between PTB_2">
						<div class="fontS_16 font_weight_bold BL_3_F55814 PL_2">
								福利社
						</div>
						<!-- <div class="color_F55814" @click="goTo">
								<span>更多福利</span>1
								<span> > </span>
						</div> -->
					</div>
					<div class="scroll_x hide_scrollbar PTB_2">
						<div class="W_32vw B_radius_10 H_44vw bg_f2f2f2 flex_shrink_0"  >
								<img style="margin-right:0.2rem;" src="../../assets/gg1.png" alt="">
								<img style="margin-right:0.2rem;" src="../../assets/gg2.png" alt="">
								<img style="margin-right:0.2rem;" src="../../assets/gg3.png" alt="">
								<img style="margin-right:0.2rem;" src="../../assets/gg4.png" alt="">

								<img style="margin-right:0.2rem;" src="../../assets/gg5.png" alt="">



						</div>
					</div>
				</div>

				<!--最新消息-->
				<div >
					<div class="flex_row_between PTB_2">
							<div class="fontS_16 font_weight_bold BL_3_F55814 PL_2">
									最新消息
							</div>
							<div @click="goMoreMsg" class="color_F55814">
									<span>更多消息</span>
									<span> > </span>
							</div>
					</div>
					<div v-if="msgList.length>0" class="PLR_2">
						<div class="flex_grow_1 bg_fff" @click="toPage(item)" v-for="(item,index) in msgList" :key="index">
								<div class="PLR_2">
										<div class="flex_row_between PT_2"  >
												<div class="a" v-if="item.message_type == 1">
												<img class="imgItem" src="../../assets/msg_1.png" alt="">  
												<div v-if="item.status == 1" class="yd"></div>
												</div>
												<div class="a" v-if="item.message_type == 2">
														<img class="imgItem" src="../../assets/msg_2.png" alt="">
														<div v-if="item.status == 1" class="yd"></div> 
												</div>
										<div class="a" v-if="item.message_type == 3">
														<img class="imgItem" src="../../assets/msg_3.png" alt="">
														<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 4" >
														<img class="imgItem" src="../../assets/msg_4.png" alt="">
														<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 5">
												<img  class="imgItem" src="../../assets/msg_5.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 6">
												<img class="imgItem" src="../../assets/msg_6.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 7">
												<img class="imgItem" src="../../assets/msg_7.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
												<div class="flex_grow_1 ML_4 BB_1_f2f2f2 PTB_3">
														<div class="flex_row_between">
																<span class="fontS_16 font_weight_bold">{{ item.message_type | msgType}}</span>
																<div class="flex">
																		<img class="icon" src="../../assets/time.png" alt="">
																		<span>{{item.date1}}</span> 
																</div>
														</div>
														<div class="color_555 textL PT_2">
																来自:{{item.company_name == "" ? "暂无" : item.company_name }}
														</div>
												</div>
										</div>
								</div>
						</div>
					</div>
					<div class="nomsg" v-else>
						<img style="width:1.5rem;height:1.5rem;" src="../../assets/nomessage.png" alt="">
						<div >- 抱歉这里没有任何消息 -</div>
					</div>
					<div style="height:2.2rem;"></div>
				</div>
			</div>
			<van-loading v-if="showLoading == true" size="45" class="loading" type="spinner" />

    </div>

</template>
<script>
import Vue from 'vue';
import {Swipe, SwipeItem, Lazyload,Toast} from 'vant';

Vue.use(Swipe).use(SwipeItem).use(Lazyload);
import '../../assets/css/common.css'
import getDate from "../../util/dateChange"

export default {
		data() {
			return {
				images: [
						require('../../assets/banner_img1.png'),
						require('../../assets/banner_img2.png'),
						require('../../assets/banner_img2.png')
				],
				hzimgs:[
					require("../../assets/hz1.png"),
					require("../../assets/hz2.png"),
					require("../../assets/hz3.png"),
				],
				msgList:[], //最新消息列表
				// show:true, 
				coopCompany:[], //合作企业列表
				bannerData:[], //轮播图列表
				showLoading:false
			}
		},
		mounted(){
			let token = localStorage.getItem("token")
			// console.log(token)
			if(token == "" || token == null){
				this.$router.push("./login")
				return
			}
			this.showLoading = true
			this.$http.post("/user/v1/Index/index",{reqType:"index"})
			.then((res)=>{
			
				res = JSON.parse(res.data)
				// console.log(res)
				if(res.code == 0){
					this.showLoading = false
					if(	res.data.messageData.length>0){
							res.data.messageData.map((value)=>{
							value.add_time = value.add_time.replace(/-/g,'/')
							let date = new Date(value.add_time)
							let date1 = getDate(date,0)
							value.date1 = date1
							return value
						})
					}
				
				this.msgList = res.data.messageData
				this.coopCompany = res.data.coopCompany
				this.bannerData = res.data.bannerData
				}
			
			})
			.catch((e)=>{
				console.log(e)})
		},
		methods: {
			toPage(item) {
				// console.log(item)
				let id = item.id
				// console.log(id)
				this.$http.post("/user/v1/message/messageSign",{reqType:"messageSign",messageId:id,type:1})
				.then((res)=>{
						let response = JSON.parse(res.data)
						// console.log(response)
						if(response.code == 0){
								// console.log(response)
								if(item.message_type == 1 || item.message_type == 5){
									let a = this.ReplaceUrl(item.content)
									window.location.href = a
								}else if(item.message_type == 6){
										this.$router.push({
											path:'/commentDetail',
											query:{
													id1:item.evaluation_id,
													type1:"msg",
													// companyName2:item.company_name
											}
										})
								}else{
									this.$router.push({
										path:'/messageDetail',
										query:{
												msgDetail: JSON.stringify(item) 
										}
									});
							}
						}else{
								Toast.fail(response.msg)
						}
				})
				.catch((e)=>{console.log(e)})
		},
			ReplaceUrl(text){
			var re =/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
			var s = text.match(re);
			let url = s[0]
			return url;
		},

			//跳转合作企业详情
			gocompanyDetail(id){
				this.$router.push({path:"./companyDetail",query:{companyId:id}})
			},
		//跳转消息详情
			goMsgDetail(item){
		// console.log(item)
				this.$http.post("/user/v1/message/messageSign",{reqType:"messageSign",messageId:item.id,type:1})
				.then((res)=>{
					res = JSON.parse(res.data)
					if(res.code == 0){
							if(item.message_type == 1 || item.message_type == 5){
								let a = this.ReplaceUrl(item.content)
								window.location.href = a
						}else if(item.message_type == 6){
							this.$router.push({
								path:'/commentDetail',
								query:{
										id:item.id
								}
							})
						}else{
							this.$router.push({
								path:'/messageDetail',
								query:{
										msgDetail: JSON.stringify(item) 
								}
							});
						}
					}
					// console.log(res)
				})
				.catch((e)=>{
					console.log(e)
				})
			
			},

			//跳转搜索
			goMsg(){
				this.$router.push("./search")
			},

			//跳转消息列表
			goMoreMsg(){
				this.$router.push("./message")
			},

			// 跳转合作企业列表
			goTo() {
				this.$router.push("./enterpriseList")
			},
		}
}
</script>
<style scoped>
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
  }
.a{
    position:relative;
}
.yd{
    width: 0.2rem;
    height: 0.2rem;
    background: #F15641; 
    position:absolute; 
    left:0.7rem;
    top:0.05rem;
    border-radius: 50%;
}
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}
.nomsg{
	text-align: center;
	margin-top:0.5rem;
}
.nomsg div{
	font-size: 0.28rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
margin-top:0.23rem;
}

.scroll_x {
	width: 100%;
	display: flex;
	justify-content: space-between;
	white-space: nowrap;
	overflow-y: hidden;
	overflow-x: scroll;
}
.imgItem{
    width: 0.8rem;
    height: 0.8rem;
}
.icon{
    width:0.25rem;
    height:0.25rem;
    margin-right:0.05rem;
}
.flex{
    display:flex;
    align-items: center;
}
.search{
	width:0.5rem;
	height:0.5rem;
	float:right
}

</style>
